import React from 'react';
import classNames from 'classnames';
import { FaCaretUp } from '@react-icons/all-files/fa/FaCaretUp';
import { FaCaretDown } from '@react-icons/all-files/fa/FaCaretDown';

const ColumnSort = ({ column }) => {
  const iconClass = (exp) => classNames('', { 'text-light': exp });
  return (
    <div className="icons-section">
      <div>
        <FaCaretUp className={iconClass(column.isSorted && !column.isSortedDesc)} />
      </div>
      <div style={{ marginTop: '-14px' }}>
        <FaCaretDown className={iconClass(column.isSorted && column.isSortedDesc)} />
      </div>
    </div>
  );
};

export default ColumnSort;
